<template>
  <!-- 学员管理页面-->
  <div class="resourceManage" :class="{ EnClass: isEn }">
    <div class="resourceManage-tool">
      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.teacherName")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="resourceManage-tool-item-option"
          v-model="form.staffName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>
      <!-- <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.workNumber")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="resourceManage-tool-item-option"
          v-model="form.staffCode"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div> -->
      <!-- <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.school") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="resourceManage-tool-item-option"
          v-model="form.schoolName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div> -->
      <!-- <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.subjects")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-select
          class="resourceManage-tool-item-option"
          v-model="form.subjectId"
          :placeholder="$t('el.common.pleaseSelect')"
          size="small"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.grade") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-select
          class="resourceManage-tool-item-option"
          v-model="form.gradeId"
          :placeholder="$t('el.common.pleaseSelect')"
          size="small"
        >
          <el-option
            v-for="item in gradeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div> -->
      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.planCourse.CourseName") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="resourceManage-tool-item-option"
          v-model="form.courseName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>
      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.courseDetails.keyPointsName") }}{{ $t("el.symbol.colon") }}
        </div>
        <el-input
          class="resourceManage-tool-item-option"
          v-model="form.knowledgeName"
          :placeholder="$t('el.common.pleaseEnter')"
          size="small"
        ></el-input>
      </div>
      <div class="resourceManage-tool-item">
        <div class="resourceManage-tool-item-title">
          {{ $t("el.schoolResourceManage.resourceState")
          }}{{ $t("el.symbol.colon") }}
        </div>
        <el-select
          class="resourceManage-tool-item-option"
          v-model="form.isOnline"
          :placeholder="$t('el.common.pleaseSelect')"
          size="small"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="resourceManage-tool-item">
        <button class="btn-query" @click="getData">
          {{ $t("el.common.search") }}
        </button>
        <button class="btn-reset" @click="reset">
          {{ $t("el.common.reset") }}
        </button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="resourceManage-table">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        :header-cell-class-name="headerStyle"
        :cell-class-name="cellStyle"
        style="width: 100%"
      >
        <template slot="empty">
          <div style="padding-top: 111px; line-height: 0">
            <img
              src="@/assets/images/pic_empty_people@2x.png"
              alt=""
              style="width: 270px"
            />
          </div>
          <div
            style="
              color: rgba(100, 108, 149, 0.25);
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 1px;
              padding-bottom: 120px;
            "
          >
            {{ $t("el.common.noData") }}
          </div>
        </template>
        <el-table-column
          prop="staffName"
          :label="$t('el.schoolResourceManage.teacherName')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="courseName"
          :label="$t('el.planCourse.CourseName')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="knowledgeName"
          :show-overflow-tooltip="true"
          :label="$t('el.courseDetails.keyPointsName')"
        >
        </el-table-column>
        <el-table-column
          prop="resKjName"
          min-width="180px"
          :label="$t('el.schoolResourceManage.resourceName')"
        >
          <template slot-scope="scope">
            <div class="resource-col">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.resJaName"
                placement="top"
              >
                <span class="resource-name">{{ scope.row.resJaName }}</span>
              </el-tooltip>
              <div style="vertical-align: middle">
                <el-button
                  type="text"
                  @click="handleLook(scope.row, 'resJaUrl')"
                  >{{ $t("el.common.view") }}</el-button
                >
                <el-button
                  type="text"
                  @click="exportFile(scope.row, 'resJaUrl')"
                  >{{ $t("el.common.download") }}</el-button
                >
              </div>
            </div>
            <div class="resource-col">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.resKjName"
                placement="top"
              >
                <span class="resource-name">{{ scope.row.resKjName }}</span>
              </el-tooltip>
              <div style="vertical-align: middle">
                <el-button
                  type="text"
                  @click="handleLook(scope.row, 'resKjUrl')"
                  >{{ $t("el.common.view") }}</el-button
                >
                <el-button
                  type="text"
                  @click="exportFile(scope.row, 'resKjUrl')"
                  >{{ $t("el.common.download") }}</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="isOnline"
          :label="$t('el.schoolResourceManage.resourceState')"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              {{
                scope.row.isOnline == 0
                  ? $t("el.schoolResourceManage.offline")
                  : scope.row.isOnline == 1
                  ? $t("el.schoolResourceManage.online")
                  : ""
              }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="onlineOperatorName"
          :label="$t('el.schoolResourceManage.operator')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="onlineOperateTime"
          :label="$t('el.schoolResourceManage.operateTime')"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column :label="$t('el.common.operate')" width="200px">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="handleUpdateOnlineState(scope.row, 1)"
              v-if="scope.row.isOnline == 0"
              >{{ $t("el.schoolResourceManage.online") }}</el-button
            >
            <el-button
              type="text"
              @click="handleUpdateOnlineState(scope.row, 0)"
              v-if="scope.row.isOnline == 1"
              >{{ $t("el.schoolResourceManage.offline") }}</el-button
            >
            <el-button
              type="text"
              :disabled="scope.row.isShared == 1"
              @click="handleShare(scope.row)"
              >{{ $t("el.schoolResourceManage.crossSchoolShared") }}</el-button
            >
            <el-button type="text" @click="getRecords(scope.row)">{{
              $t("el.schoolResourceManage.log")
            }}</el-button>
            <el-button type="text" @click="delRecords(scope.row)">{{
              $t("el.common.delete")
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页  -->
    <div class="resourceManage-page" v-show="tableData.length > 0">
      <el-pagination
        layout="prev, pager, next"
        :current-page.sync="params.page"
        :page-size="params.limit"
        :total="total"
      >
      </el-pagination>
      <span class="el-pagination__total"
        >{{ $t("el.pagination.totalname") }}{{ Math.ceil(total / params.limit)
        }}{{ $t("el.pagination.pages") }} {{ total
        }}{{ $t("el.pagination.items") }}
      </span>
    </div>
    <ManageOption
      v-if="dialogManage"
      ref="manageConfig"
      :showRemark="true"
      :title="$t('el.schoolResourceManage.log')"
    />
    <el-dialog :visible.sync="uporDowndialogVisible" width="420px">
      <div class="offline">
        <div Slot="title" class="offlinetitle">
          {{ $t("el.infrastructure.WhetherLogoffResources") }}
        </div>
        <div class="offlineinfo">
          {{ $t("el.infrastructure.resourcesNotVisible") }}
        </div>
        <el-input
          type="textarea"
          :rows="4"
          resize="none"
          maxlength="500"
          show-word-limit
          :placeholder="$t('el.infrastructure.logoffReasonLeaveResources')"
          v-model="textarea"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="uporDowndialogVisible = false">{{
          $t("el.common.cancel")
        }}</el-button>
        <el-button size="small" type="primary" @click="sureOffline">{{
          $t("el.common.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 上线弹框 -->
    <el-dialog :visible.sync="updialogVisible" width="420px">
      <div class="offline">
        <div Slot="title" class="offlinetitle">
          {{ $t("el.infrastructure.WhetherOnlinedResources") }}
        </div>
        <div class="uplineinfo">
          <div class="uplineinfo-icon">
            <i class="el-icon-warning" style="color: #e6a23c"></i>
          </div>
          <div class="uplineinfo-con">
            {{ $t("el.infrastructure.ResourceWillAvailable") }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="updialogVisible = false">{{
          $t("el.common.cancel")
        }}</el-button>
        <el-button size="small" type="primary" @click="sureupline">{{
          $t("el.common.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 跨校共享弹框 -->
    <el-dialog :visible.sync="sharedialogVisible" width="420px">
      <div class="offline">
        <div Slot="title" class="offlinetitle">
          {{ $t("el.schoolResourceManage.crossSchoolSharedWhether") }}
        </div>
        <div class="uplineinfo">
          <div class="uplineinfo-icon">
            <i class="el-icon-warning" style="color: #e6a23c"></i>
          </div>
          <div class="uplineinfo-con">
            {{ $t("el.schoolResourceManage.crossSchoolSharedDesc") }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="sharedialogVisible = false">{{
          $t("el.common.cancel")
        }}</el-button>
        <el-button size="small" type="primary" @click="sureshare">{{
          $t("el.common.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  queryCollectiveList,
  updateOnlineState,
  platformShare,
  delRecord,
} from "@/api/home/resourcemanage";
import { queryAuditRecordsList } from "@/api/home/auditmanage";
import { getVersion } from "@/api/manage/compared-analyze";
import ManageOption from "../components/manageOption.vue";
import gradeAndSubject from "@/mixin/gradeAndSubject.js";

export default {
  name: "resourceManage",
  // 注册组件
  components: {
    ManageOption,
  },
  mixins: [gradeAndSubject],
  // 定义数据
  data() {
    return {
      loading: false, // 列表加载状态
      tableData: [],
      form: {
        // 查询条件
        staffName: "",
        // staffCode: "",
        // schoolName: "",
        // subjectId: "",
        // gradeId: "",
        courseName: "",
        knowledgeName: "",
        isOnline: null,
      },
      params: {
        // 分页
        limit: 10,
        offset: 0,
        page: 1,
      },
      total: 0,

      dialogManage: false,
      gradeOptions: [], // 年级选项
      subjectOptions: [], // 科目选项
      uporDowndialogVisible: false,
      updialogVisible: false,
      sharedialogVisible: false,
      textarea: "", // 下线理由
      downRow: null,
      upRow: null,
      shareRow: null,
      isEn: false,
    };
  },
  // 计算属性
  computed: {
    ...mapGetters(["eduAuth"]),
    options() {
      return [
        {
          value: 0,
          label: this.$t("el.schoolResourceManage.offline"),
        },
        {
          value: 1,
          label: this.$t("el.schoolResourceManage.online"),
        },
        {
          value: "",
          label: this.$t("el.schoolResourceManage.all"),
        },
      ];
    },
  },
  // 侦听器
  watch: {
    // 监听页数
    "params.page"(newVal, oldVal) {
      // 改变参数
      this.params.offset = this.params.limit * (this.params.page - 1);
      this.getData();
    },
    "$i18n.locale": {
      immediate: true,
      handler(val, old) {
        this.isEn = val === "en_US";
      },
    },
  },
  // 创建完成钩子
  created() {
    this.getData();
    this.optionInit();
  },
  // 挂在完成钩子
  mounted() {},
  // 方法定义
  methods: {
    headerStyle({ row, rowIndex }) {
      return "bskHeader";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "bskCell";
    },
    getData() {
      this.loading = true;
      let paramsData = {
        ...this.params,
        ...this.form,
      };
      delete paramsData["page"];
      queryCollectiveList(paramsData)
        .then(({ data }) => {
          this.tableData = data.records;
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    reset() {
      for (let item in this.form) {
        this.form[item] = "";
      }
      this.form.isOnline = null;
      this.params.page = 1;
      this.getData();
    },
    /**
     * 查看资源
     * @param {*} row
     */
    handleLook(row, type) {
      // let officePreviewUrl = this.$options.filters["officePreviewUrl"];
      // window.open(
      //   officePreviewUrl(row[type], this.getFormat(row[type]), false, true)
      // );
      if (type === "resKjUrl") {
        getVersion({ resourceId: row.resKjId }).then((res) => {
          let version = res.data;
          let format = row.resKjUrl.substring(
            row.resKjUrl.lastIndexOf(".") + 1
          );
          if (
            ["ppt", "pptx"].indexOf(
              row.resKjUrl.substring(row.resKjUrl.lastIndexOf(".") + 1)
            ) !== -1
          ) {
            this.getwpsInit(
              row.resKjId,
              format,
              version,
              2,
              row.courseId,
              "KJ"
            );
          } else {
            this.getwpsInit(
              row.resKjId,
              format,
              version,
              1,
              row.courseId,
              "KJ"
            );
          }
        });
      }
      if (type === "resJaUrl") {
        getVersion({ resourceId: row.resJaId }).then((res) => {
          let version = res.data;
          let format = row.resJaUrl.substring(
            row.resJaUrl.lastIndexOf(".") + 1
          );
          // this.getwpsInit(row.resJaId, format, version, 1);
          if (
            ["doc", "docx"].indexOf(
              row.resJaUrl.substring(row.resJaUrl.lastIndexOf(".") + 1)
            ) !== -1
          ) {
            this.getwpsInit(
              row.resJaId,
              format,
              version,
              1,
              row.courseId,
              "JA"
            );
          } else {
            this.getwpsInit(
              row.resJaId,
              format,
              version,
              2,
              row.courseId,
              "JA"
            );
          }
        });
      }
    },
    /**
     * 跳转到查看WPS页面
     */
    getwpsInit(id, format, version, types, courseId, resourceType) {
      this.$utils.openWindow(
        "lookWps?id=" +
          id +
          "&format=" +
          format +
          "&version=" +
          version +
          "&type=" +
          types +
          "&courseId=" +
          courseId +
          "&resourceType=" +
          resourceType
      );
    },
    /**
     *下载资源
     * @param {*} row
     */
    exportFile(row, type) {
      console.log(row.resKjUrl);
      let resourceType = "";
      let resourceName = "";
      if (type === "resKjUrl") {
        resourceType = "kj";
        resourceName = row.resKjName;
      } else {
        resourceType = "ja";
        resourceName = row.resJaName;
      }
      // let queryParams = { fileName: resourceName, url: row[type] };
      let queryParams = {
        displayName: resourceName,
        objectName: row[type],
        isDownload: 1,
      };
      this.$utils.download("/obs/info/download", queryParams, {
        method: "get",
      });
    },
    /**
     *上线
     * @param {*} row
     * isOnline 是否上线：0-否，1-是
     */
    handleUpdateOnlineState(row, isOnline) {
      let title = "";
      let text = "";
      if (isOnline === 1) {
        // 上线
        this.updialogVisible = true;
        this.upRow = row;
      } else {
        this.uporDowndialogVisible = true;
        this.downRow = row;
        this.textarea = "";
      }
    },
    /**
     * 确认上线
     */
    sureupline() {
      let params = {
        id: this.upRow.id,
        isOnline: 1,
      };
      updateOnlineState(params).then((data) => {
        this.$message.success(data.message);
        this.reset();
      });
      this.updialogVisible = false;
    },
    /**
     * 确认下线
     */
    sureOffline() {
      this.uporDowndialogVisible = false;
      let params = {
        id: this.downRow.id,
        isOnline: 0,
        isOnlineContext: this.textarea,
      };
      updateOnlineState(params).then((data) => {
        this.$message.success(data.message);
        this.reset();
      });
    },
    /**
     *跨校共享
     * @param {*} row
     * isShared 0-未共享 1-共享
     */
    handleShare(row) {
      this.sharedialogVisible = true;
      this.shareRow = row;
      // this.$confirm(
      //   "<b>是否确认跨校共享该配套资源？</b><div>跨校共享后，资源将会展示在“集团资源”中，对所有用户可见哦</div>",
      //   "",
      //   {
      //     confirmButtonText: this.$t("el.common.confirm"),
      //     cancelButtonText: this.$t("el.common.cancel"),
      //     type: "warning",
      //     dangerouslyUseHTMLString: true,
      //     iconClass: "icon-confirm el-icon-warning",
      //   }
      // )
      //   .then(() => {
      //     platformShare({ id: row.id }).then((data) => {
      //       this.$message.success(data.message);
      //       this.reset();
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消",
      //     });
      //   });
    },
    /**
     * 确认跨校共享
     */
    sureshare() {
      this.sharedialogVisible = false;
      platformShare({
        id: this.shareRow.id,
        originTenantId: this.shareRow.originTenantId,
      }).then((data) => {
        this.$message.success(data.message);
        this.reset();
      });
    },
    /**
     * 操作日志
     * @param {*} params
     * operateType 0-跨校共享，1-审核通过，2-退回修改，3-审核通过并上线、4-上线，5-下线，6-加精，7-取消加精，8-撤回，9-提交审核
     */
    getRecords(row) {
      this.dialogManage = true;
      queryAuditRecordsList({
        offset: 0,
        limit: 1000,
        packageId: row.packageId,
        operateType: [0, 4, 5],
        category: 1,
      }).then(({ data }) => {
        this.$nextTick(() => {
          this.$refs.manageConfig && this.$refs.manageConfig.init(data.records);
        });
      });
    },
    // 删除共享记录
    delRecords(row) {
      this.$confirm(
        this.$t("el.common.deleteData"),
        this.$t("el.common.prompt"),
        {
          confirmButtonText: this.$t("el.common.delete"),
          cancelButtonText: this.$t("el.common.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          delRecord(row.id).then(({ data }) => {
            this.$nextTick(() => {
              this.getData();
            });
          });
        })
        .catch(() => {
          console.log(this.$t("el.common.cancel"));
        });
    },
    /**
     * 获取文件格式
     * @param {*} url
     */
    getFormat(url) {
      let formatIndex = url.lastIndexOf(".");
      let format = "";
      if (formatIndex >= 0) {
        format = url.substring(formatIndex + 1);
      }
      return format;
    },
    /**
     * 选项初始化
     */
    optionInit() {
      this.handleGetGradeAndSubject(); // mixin获取年级与科目
    },
  },
};
</script>

<style lang="less" scoped>
.resourceManage {
  background: #fff;
  padding: 18px 0 46px 0;
  border-radius: 4px;
  &-tool {
    // height: 32px;
    line-height: 32px;
    margin: 20px 50px 0 51px;
    button {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(249, 249, 249, 1);
      height: 32px;
      display: flex;
      background: @themeBlue;
      border-radius: 5px;
      float: left;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      outline: none;
    }
    .btn-reset,
    .btn-query {
      width: 65px;
    }
    .btn-query {
      margin-left: 0;
    }
    .btn-reset {
      background-color: #fff;
      color: #000;
      border: 1px solid #dcdfe6;
    }
    &-title {
      min-width: 120px;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(70, 70, 70, 1);
    }
    &-item-title {
      font-size: 14px;
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #606266;
    }
    &-btnicon {
      width: 14px;
      height: 14px;
      margin-right: 3px;
    }
    &-item-option {
      width: 180px;
    }
  }
  &-table {
    padding: 0 50px 0 51px;
    /deep/.bskHeader {
      background-color: #f5f6f7 !important;
      color: #323232;
      text-align: center !important;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      font-weight: normal;
      height: 50px;
      padding: 0;
      .cell {
        white-space: pre-line;
      }
    }
    /deep/.bskCell {
      text-align: center !important;
      color: #707276;
      font-size: 14px;
      height: 46px;
      padding: 0;
      .cell {
        line-height: 20px;
        div {
          width: 100%;
          line-height: 20px;
        }
      }
    }
    /deep/.el-button--text {
      border: 0;
      color: @themeBlue;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: #f1f6ff;
    }
  }

  &-page {
    padding: 39px 50px 0 51px;
    text-align: center;
    /deep/ .el-pager li {
      height: 30px;
      line-height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 50%;
      padding: 0 0px;
      margin: 0 5px;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
      font-weight: 400;
      color: rgba(40, 40, 40, 1);
    }
    /deep/ .el-pager li.active {
      background: @themeBlue;
      color: #fff;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
    }
    /deep/.el-pagination__total {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(40, 40, 40, 1);
    }
    /deep/.el-pagination button {
      height: 30px;
      line-height: 30px;
    }
    /deep/ .el-pagination button:hover {
      color: @themeBlue;
    }
  }
  /deep/ .el-table th.gutter {
    display: table-cell !important;
  }
  /deep/ .el-table__body .bskCell:nth-child(4) .cell {
    padding: 0;
  }
  .resource-col {
    padding: 6px 10px;
    &:first-child {
      border-bottom: 1px solid #ebeef5;
    }
  }
  .resource-col /deep/ .el-button {
    padding: 8px 0 0;
  }
  .resource-name {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    white-space: nowrap;
    vertical-align: middle;
  }
}
/deep/ .el-button.is-disabled.el-button--text {
  color: #ccc;
}
.resourceManage-page {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
.offline {
  margin-top: -60px;
  .offlinetitle {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 36px;
    text-align: left;
  }
  .offlineinfo {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    line-height: 14px;
    text-align: left;
    margin: 15px 0 21px 0;
  }
  .uplineinfo {
    margin: 15px 0 0px 0;
    display: flex;
    .uplineinfo-icon {
      height: 18px;
      font-size: 18px;
    }
    .uplineinfo-con {
      padding: 0 10px 0 10px;
      text-align: left;
      color: #606266;
      line-height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
  }
}
/deep/ .el-input__count {
  right: 18px;
  background: transparent;
}
</style>
